import React from "react";
import './hero-banner.scss';
import logo from './../../assets/img/logo-removebg.png';

const HeroBanner: React.FC = () => {
    return (

        <div className="hero-banner">
            <div className="hero-banner__content container">
                <img src={logo} alt="Logo" className="hero-banner__logo"/>
                <div className={"hero-banner__header"}>
                    <h1 className="hero-banner__title">LA MIGLIORE QUALITÀ</h1>
                    <p className="hero-banner__subtitle">nella cucina del tuo ristorante</p>
                </div>

                <p className="hero-banner__description">
                    <span className={"hero-banner__description__paragraph-spacing"}>
                        IAS Service è la chiave per una cucina di successo. </span>

                    <span className={"hero-banner__description__paragraph-spacing"}>
                        Offriamo non solo prodotti di altissima qualità, ma anche un servizio personalizzato che si
                        adatta alle esigenze specifiche della tua attività.
                    </span>

                    <span className={"hero-banner__description__paragraph-spacing hero-banner__description__bold"}>Alza il livello della tua cucina oggi!</span>

                    <span className={"hero-banner__description__paragraph-spacing hero-banner__description__bold"}>Compila il modulo di contatto qui sotto per ricevere il nostro
                        esclusivo listino di prodotti.</span>
                </p>

                <a href="#jotform-container" className="hero-banner__cta">COMPILA IL FORM</a>
            </div>

        </div>
    );
}

export default HeroBanner;