import React from "react";
import './thank-you.scss';

const ThankYou = () => {
    return (
        <div className="thank-you-page">
            <div className="thank-you-container">
                <h1>Grazie per aver completato la registrazione!</h1>
                <p>La tua richiesta è stata inoltrata con successo. Il nostro team commerciale si metterà in contatto con te al più presto.</p>
            </div>
        </div>
    );
}

export default ThankYou;