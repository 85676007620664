import './contanct.scss';
import React from 'react';

const Contact = () => {

    return (
        <div className={'contact'}>
            <div className={'contact__header'}>
                <h3>
                    Contattaci Ora!
                </h3>
                <p>
                    Per ricevere informazioni, listino prezzi e preventivi COMPILA IL FORM qui sotto.
                </p>
            </div>

            <div className={'contact__body'}>
                <div id="jotform-container">
                    <iframe
                        id="JotFormIFrame-233244315415044"
                        title="Modulo"
                        onLoad={() => window.parent.scrollTo(0, 0)}
                        allowFullScreen={true}
                        allow="geolocation; microphone; camera"
                        src="https://form.jotform.com/233244315415044"
                        frameBorder={0}
                        style={{minWidth: "100%", maxWidth: "100%", height: "539px", border: "none"}}
                        scrolling="no"
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

export default Contact;