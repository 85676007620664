import React from "react";
import './footer.scss';

const Footer: React.FC = () => {
    return (
        <footer className={"footer"}>
            <div className="footer__content">
                <p>Partita IVA: 05456410876</p>
                <p>Codice Fiscale: 05456410876</p>
                <p>Rag. Sociale: IAS SERVICE S.R.L.</p>
                <p>Indirizzo: VIA ABRUZZI 3 - 95014 - GIARRE (CT)</p>
            </div>
        </footer>
    )
}

export default Footer;