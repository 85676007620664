import React from "react";
import './user-review.scss';

interface UserReviewProps {
    name: string;
    review: string;
}

const UserReview: React.FC<UserReviewProps> = ({name, review}) => {
    return (
        <div className={'review'}>
            
            <div className={'review__name'}>
                <h3>{name}</h3>
            </div>

            <div className={"review__separator"}>
                “
            </div>

            <div className={'review__comment'}>
                <p>{review}</p>
            </div>
            
        </div>
    )
}

export default UserReview;