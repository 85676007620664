import "./top-banner.scss";
import React from "react";

const TopBanner: React.FC = () => {
    return (
        <div className={"top-banner"}>
            Siamo qui per te dal Lunedì al Sabato, dalle 9:00 alle 12:00 e dalle 16:00 alle 19:00. Non esitare a chiamarci per qualsiasi necessità!  <a href={"tel:+390956170860"}>Chiama ora!</a>
        </div>
    )
}
export default TopBanner;