import './users-review.scss';
import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserReview from "../user-review/UserReview";

interface reviewPropsInterface {
    name: string;
    review: string;
}
const reviewProps: reviewPropsInterface[] = [
    {
        name: "Luigi Foti",
        review: "Sono cliente di Ias Service da diversi mesi e sono estremamente soddisfatto dei loro prodotti e servizi. La qualità dei prodotti surgelati è eccellente, e la varietà offerta ha davvero arricchito il nostro menu. La consegna è sempre puntuale, e il personale è cordiale e professionale. Consiglierei sicuramente Ias Service a chiunque cerchi un fornitore affidabile nel settore Horeca."
    },
    {
        name: "Emanuele Migliore",
        review: "Prodotti di alto livello, serietà e disponibilità Stefano Silvestro si distingue dalla massa comune."
    },
    {
        name: "Claudio Giuffrida",
        review: "La puntualità nelle consegne è una caratteristica distintiva di Ias Service, garantendo sempre un approvvigionamento tempestivo e affidabile. Il personale dimostra una cortesia e professionalità notevoli, rendendo ogni interazione un'esperienza positiva. La loro attenzione ai dettagli e l'impegno nel soddisfare le esigenze dei clienti sono davvero encomiabili."
    },
];

const UsersReview = () => {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
    };

    return (
        <div className="users-review">
            <div className={'users-review__header'}>
                <h2>
                    Dicono di noi
                </h2>
                <svg xmlns="http://www.w3.org/2000/svg" width="170" height="13" viewBox="0 0 170 13" fill="none">
                    <g clipPath="url(#clip0_65_3)">
                        <path d="M131.771 0H38.2288V2.05869H131.771V0Z" fill="white"/>
                        <path d="M170 10.9414H0V13.0001H170V10.9414Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_65_3">
                            <rect width="170" height="13" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div className={'users-review__slider'}>
                <Slider {...settings}>
                    {reviewProps.map((review, index) => {
                        return (
                            <UserReview key={index} name={review.name} review={review.review}/>
                        )
                    })}
                </Slider>
            </div>
        </div>
    );
}

export default UsersReview;