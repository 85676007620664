import React from 'react';
import './App.scss';
import HeroBanner from "./components/hero-banner/HeroBanner";
import Footer from "./components/footer/Footer";
import Services from "./components/services/Services";
import Company from "./components/company/Company";
import UsersReview from "./components/users-review/UsersReview";
import Contact from "./components/contact/Contact";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ThankYou from "./components/thank-you/ThankYou";
import TopBanner from './components/top-banner/TopBanner';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    <div className="app-wrapper">
                        <TopBanner/>
                        <HeroBanner/>
                        <Services/>
                        <Company/>
                        <UsersReview/>
                        <Contact/>
                        <Footer/>
                    </div>
                }/>
                <Route path="/thank-you" element={
                    <ThankYou/>
                }/>
            </Routes>
        </Router>
    );
}

export default App;
